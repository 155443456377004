var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { FILTERS_EQUAL, MAINTAIN_PERMISSION, STERILIZATION_SPECIFICATIONS_REPORTS } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "p-5 sterilisation-processes" }, [_c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("div", { staticClass: "p-3" }, [_c("div", { staticClass: "row" }, [_c("div", { staticClass: "col-lg-3 col-md-12" }, [_c("select-input", { attrs: { "form-item": "", "label": "Method", "value": _vm.data.Method, "placeholder": "Select Method", "reference": "sterilization.common.methods", "source": "code", "source-label": "name", "span": 12 }, on: { "change": _vm.onChangeMethod } })], 1), _c("div", { staticClass: "col-lg-3 col-md-12" }, [_c("select-input", { attrs: { "form-item": "", "label": "Cooker", "value": _vm.data.Cooker, "placeholder": "Select Cooker", "reference": "sterilization.common.cooker-nos", "source": "cookerNo", "source-label": "cookerNo", "span": 12, "disabled": _vm.isDisableCooker }, on: { "change": function($event) {
      return _vm.storeValue($event, "Cooker");
    } } })], 1), _c("div", { staticClass: "col-lg-3 col-md-12" }, [_c("select-input", { attrs: { "form-item": "", "advanced-filters": _vm.canSizesAdvancedFilters, "delete-filter": "IsInactive", "label": "Can Size", "lazy": "", "value": _vm.data.CanSizeId, "placeholder": "Select Can Size", "reference": "sterilization.common.can-sizes", "source": "id", "source-label": "name", "span": 12, "disabled": _vm.isDisableCanSize }, on: { "change": function($event) {
      return _vm.storeValue($event, "CanSizeId");
    } } })], 1), _c("div", { staticClass: "col-lg-3 col-md-12" }, [_c("label", [_vm._v("Sort By")]), _c("a-radio-group", { attrs: { "value": _vm.data.SortBy, "name": "radioGroup", "default-value": 1 }, on: { "change": function($event) {
      return _vm.storeValue($event.target.value, "SortBy");
    } } }, [_c("a-radio", { attrs: { "value": 1 } }, [_vm._v("Process Temp")]), _c("a-radio", { attrs: { "value": 2 } }, [_vm._v("Process Time")])], 1)], 1)]), _c("div", { staticClass: "d-flex justify-content-end border-top mt-3 pt-3" }, [_c("a-button", { staticClass: "ml-3", attrs: { "type": "default" }, on: { "click": _vm.reset } }, [_vm._v("Clear All")]), _vm.can(_vm.permissions.maintain) ? _c("a-button", { staticClass: "ml-3", attrs: { "loading": _vm.showPreviewLoader, "type": "primary", "disabled": !_vm.data.Method }, on: { "click": function($event) {
      return handleSubmit(_vm.submit);
    } } }, [_vm._v("Preview")]) : _vm._e()], 1)])];
  } }]) })], 1);
};
var staticRenderFns$1 = [];
var SterilisationProcesses_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "SterilisationProcesses",
  inject: ["apiUrl", "crud", "can"],
  data() {
    return {
      canSizesAdvancedFilters: [
        ["Facility", { operator: FILTERS_EQUAL, value: "PK,PP,PT" }]
      ],
      data: {
        Method: "",
        Cooker: "",
        CanSizeId: "",
        SortBy: 1
      },
      previewLoading: false,
      permissions: {
        maintain: MAINTAIN_PERMISSION
      },
      isDisableCooker: true,
      isDisableCanSize: true
    };
  },
  computed: {
    showPreviewLoader() {
      return this.previewLoading;
    }
  },
  mounted() {
    this.crud.deleteFilter("IsInactive");
  },
  methods: {
    storeValue(val, key) {
      this.data[key] = val;
    },
    reset() {
      this.data = {
        Method: "",
        Cooker: "",
        CanSizeId: "",
        SortBy: 1
      };
    },
    async submit() {
      const data = {
        report: "SterilisationProcesses",
        reportType: "steri",
        reportParams: this.data
      };
      this.previewLoading = true;
      this.axios.post(`${this.apiUrl}/identities/reports`, data).then((res) => {
        if (!res || !res.data || !res.data.reportViewerUri) {
          this.$notification["error"]({
            message: "Report not found!"
          });
        } else {
          window.open(res.data.reportViewerUri, "_blank");
        }
      }, (err) => {
        this.$notification["error"]({
          message: err.message || "Errors!"
        });
      }).finally(() => this.previewLoading = false);
    },
    onChangeMethod(e) {
      this.storeValue(e, "Method");
      this.isDisableCooker = e === "CC" || e === "HY" || e === "PHY" ? false : true;
      this.isDisableCanSize = !this.isDisableCooker || e === "SRS" || e === "ROR" || e === "SRR" || e === "PHY" ? false : true;
      this.data = __spreadProps(__spreadValues({}, this.data), { CanSizeId: "", Cooker: "" });
      this.storeValue("", "Cooker");
      this.storeValue("", "CanSizeId");
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "7ed19a5c", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var SterilisationProcesses = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "sterilization.common.can-sizes", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.common.cooker-nos", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.common.methods", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "page": _vm.page, "edit": _vm.SterilisationProcesses, "redirect-route": "/sterilization-specifications/reports", "name": "sterilization.sterilisation-processes", "api-url": _vm.apiUrl } })], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  name: "SterilisationProcesses",
  data() {
    return {
      SterilisationProcesses,
      page: STERILIZATION_SPECIFICATIONS_REPORTS,
      apiUrl: "#{VUE_APP_API_URL}#",
      itemsMenu: [
        {
          key: "reports",
          title: "Reports",
          path: "/sterilization-specifications/reports"
        },
        {
          key: "SterilisationProcesses",
          title: "Sterilisation Processes",
          path: "/sterilization-specifications/reports/sterilisation"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
